$(document).ready(function() {
    function menuerzekenyseg() {
        var menuerzekenyseg = 0.005;
        var valtozo = $(window).scrollTop() * menuerzekenyseg;
        var menupaddingszorzo = 10;
        if($( window ).width() < 992){menupaddingszorzo = 5}
        if(valtozo < 1) {
            $('.topnav').css({'background':'linear-gradient(180deg, rgba(0, 0, 0, 0.4) 12.5%, rgba(8, 81, 72, 0) 100%)','background-color': 'rgba(8,81,72,'+valtozo+')', 'padding-top': 15-(valtozo * menupaddingszorzo)+'px', 'padding-bottom': 15-(valtozo * menupaddingszorzo)+'px'});
            $('.navbar-extra button').eq(0).css('padding', 0.85-(valtozo/3)+'rem 1.5rem');
        } else {
            $('.topnav').css({'background':'unset','background-color': 'rgb(8,81,72)', 'padding-top': 15-menupaddingszorzo+'px', 'padding-bottom': 15-menupaddingszorzo+'px'});
            $('.navbar-extra button').eq(0).css('padding', '0.5rem 1.5rem');
        }
    }
    $(window).scroll(function() {menuerzekenyseg()});
    menuerzekenyseg();
    
    $('#elmenycarousel').carousel({interval: false});
    $('#elmenyoszlopok .elmenyoszlop').hover(function () {
        $('#elmenycarousel').carousel($(this).prevAll().length);
    });

    var scrollSzam = {};
    var scrollRun = {};
    function scroll(id) {
        if(!scrollRun[id]) {
            scrollRun[id] = true;
            if(scrollSzam[id] > 0) {
                --scrollSzam[id];
                $('#'+id+'.oldal-scroll .oldal-scroll-elem').eq(0).animate({marginLeft:'-'+$('#'+id+'.oldal-scroll .oldal-scroll-elem').eq(0).outerWidth()+'px'}, 'slow', 'swing', function() {
                    $(this).hide().removeAttr('style').insertAfter($('#'+id+'.oldal-scroll .oldal-scroll-elem').last()).show();
                    scrollRun[id] = false;
                    if(scrollSzam[id] != 0) {
                        scroll(id);
                    }
                });
            } else {
                ++scrollSzam[id];
                $('#'+id+'.oldal-scroll .oldal-scroll-elem').last().hide().attr('style', 'margin-left: -'+$('#'+id+'.oldal-scroll .oldal-scroll-elem').eq(0).outerWidth()+'px;').insertBefore($('#'+id+'.oldal-scroll .oldal-scroll-elem').first()).show();
                $('#'+id+'.oldal-scroll .oldal-scroll-elem').eq(0).animate({marginLeft:'0'}, 'slow', 'swing', function () {
                    $(this).removeAttr('style');
                    scrollRun[id] = false;
                    if(scrollSzam[id] != 0) {
                        scroll(id);
                    }
                });
            }
        }
    }
    function oldalScroll() {
        scrollok = $('.oldal-scroll');
        for(i = 0; i < scrollok.length; ++i ) {
            var id = scrollok.eq(i).attr('id');
            scrollSzam[id] = 0; scrollRun[id] = false;
            $('.oldal-scroll-gombok[data-oldal-scroll='+id+'] .jobbra').click(function () {var id = $(this).parent().attr('data-oldal-scroll'); ++scrollSzam[id];scroll(id);});
            $('.oldal-scroll-gombok[data-oldal-scroll='+id+'] .balra').click(function () {var id = $(this).parent().attr('data-oldal-scroll'); --scrollSzam[id];scroll(id);});
        }
    }
    oldalScroll();

    $('.footer-custom-nav h6').click(function () {
        $(this).siblings().slideToggle();
    });

    function utazasScroll(irany) {
        if(irany == 'jobbra') {
            $('.utazas-kiemelt-elem:first').animate({marginLeft:'-'+$('.utazas-kiemelt-elem:first').outerWidth()+'px'}, 'slow', 'swing', function() {
                $(this).hide().removeAttr('style').insertAfter($('.utazas-kiemelt-elem:last')).show();
            });
            $('.utazas-scroll-kepek-keret').css('height', '135%').append('<div class="col-12 col-sm-4 col-md-12 utazas-scroll-elem"><img src="'+$('.utazas-kiemelt-elem').eq(2).find('img').attr('src').split('.')[0]+'_thumb.jpg" class="w-100" alt=""></div>').animate({marginTop:'-121%'}, 'slow', 'swing', function() {
                $('.utazas-scroll-elem:first').remove();$('.utazas-scroll-kepek-keret').css({'height': '100%', 'margin-top': '0px'});
            });
        } else {
            $('.utazas-kiemelt-elem:last').hide().attr('style', 'margin-left: -'+$('.utazas-kiemelt-elem:last').outerWidth()+'px;').insertBefore($('.utazas-kiemelt-elem:first')).show();
            $('.utazas-kiemelt-elem:first').animate({marginLeft:'0px'}, 'slow', 'swing', function() {

            });
            $('.utazas-scroll-kepek-keret').css({'height': '135%', 'margin-top': '-121%'}).prepend('<div class="col-12 col-sm-4 col-md-12 utazas-scroll-elem"><img src="'+$('.utazas-kiemelt-elem').eq($('.utazas-kiemelt-elem').length - 2).find('img').attr('src').split('.')[0]+'_thumb.jpg" class="w-100" alt=""></div>').animate({marginTop:'-0%'}, 'slow', 'swing', function() {
                $('.utazas-scroll-elem:last').remove();$('.utazas-scroll-kepek-keret').css('height', '100%');
            });
        }
    }
    $('.utazas-scroll-gombok button').click(function () {utazasScroll($(this).attr('data-scroll'));});

});